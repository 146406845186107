// Generated by Framer (3ae0a1a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/vFs6Ccyt8";

const cycleOrder = ["Jf2I_wEQb", "f0gcwXjJu"];

const serializationHash = "framer-EO4VL"

const variantClassNames = {f0gcwXjJu: "framer-v-1xh59bz", Jf2I_wEQb: "framer-v-1pcrcph"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "Jf2I_wEQb", Open: "f0gcwXjJu"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "FAQ", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Jf2I_wEQb"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Jf2I_wEQb", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1rcefkx = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {const res = await PWSlWeqS6(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1pcrcph", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"Jf2I_wEQb"} onMouseEnter={onMouseEnter1rcefkx} ref={ref ?? ref1} style={{backgroundColor: "rgb(253, 252, 236)", ...style}} {...addPropertyOverrides({f0gcwXjJu: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1xhryuj"} data-styles-preset={"vFs6Ccyt8"}>FAQ</motion.p></React.Fragment>} className={"framer-1vm0fj1"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"ZC93Hkx7O"} style={{"--framer-paragraph-spacing": "0px"}} text={DApvjrdfG} variants={{f0gcwXjJu: {"--extracted-r6o4lv": "rgb(241, 102, 63)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({f0gcwXjJu: {children: <React.Fragment><motion.p className={"framer-styles-preset-1xhryuj"} data-styles-preset={"vFs6Ccyt8"} style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(241, 102, 63))"}}>FAQ</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EO4VL.framer-1z0u1v9, .framer-EO4VL .framer-1z0u1v9 { display: block; }", ".framer-EO4VL.framer-1pcrcph { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-EO4VL .framer-1vm0fj1 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-EO4VL.framer-1pcrcph { gap: 0px; } .framer-EO4VL.framer-1pcrcph > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-EO4VL.framer-1pcrcph > :first-child { margin-left: 0px; } .framer-EO4VL.framer-1pcrcph > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 34
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"f0gcwXjJu":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZ3jU8aff3: React.ComponentType<Props> = withCSS(Component, css, "framer-EO4VL") as typeof Component;
export default FramerZ3jU8aff3;

FramerZ3jU8aff3.displayName = "Trigger Copy";

FramerZ3jU8aff3.defaultProps = {height: 42, width: 34};

addPropertyControls(FramerZ3jU8aff3, {variant: {options: ["Jf2I_wEQb", "f0gcwXjJu"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "FAQ", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerZ3jU8aff3, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})